<template>
  <div class="w-100 padding-top--50">
    <div class="login-form login-signin">
      <!-- FORM -->
      <div class="content-review">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <!-- TITLE -->
            <b-col cols="12">
              <div class="text-center mb-10 mb-lg-20">
                <h3>
                  {{ $t("REVIEWS.TITLE", { campus: campusName }) }}
                </h3>
              </div>
            </b-col>

            <!-- NAME -->
            <b-col lg="6" md="6" sm="6" cols="12">
              <v-text-field
                v-model="name"
                :label="$t('REVIEWS.NAME')"
                :rules="validations.required"
                required
                outlined
                dense
              />
            </b-col>

            <!-- CALIFICATION -->
            <b-col lg="12" md="12" sm="12" cols="12" class="mt-3 mb-5">
              <label class="required-label font-size-1-3">
                {{ $t("REVIEWS.CALIFICATION") }}
              </label>
              <v-rating
                v-model="calification"
                :rules="validations.required"
                required
                color="yellow darken-3"
                background-color="grey darken-1"
                hover
                large
              ></v-rating>
            </b-col>

            <!-- REVIEW -->
            <b-col lg="12" md="12" sm="12" cols="12">
              <v-textarea
                v-model="review"
                :label="$t('REVIEWS.REVIEW')"
                outlined
                dense
              />
            </b-col>
          </v-row>
        </v-form>
        <div class="d-flex justify-content-end mb-5">
          <v-btn
            depressed
            color="primary"
            :loading="save_loading"
            @click="submit"
          >
            {{ $t("GENERAL.SEND") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="message-bottom">
      {{ $t("REVIEWS.BOTTOM_MESSAGE", { campus: campusName }) }}
    </div>
  </div>
</template>

<script>
import Rules from "@/core/services/utils/validations";
import { Decrypt } from "@/core/services/utils/encryptService";
import { UPDATE_REVIEW } from "@/core/services/store/reviews/reviews.module";

export default {
  data() {
    return {
      valid: false,
      save_loading: false,
      name: null,
      review: null,
      calification: null,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  props: {
    campusName: {
      type: String,
      default: "",
    },
  },
  methods: {
    /**
     * Create new user
     * @method submit
     * @returns 'submit' object whit data
     */
    submit() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.save_loading = true;
        const { token } = this.$route.params;
        const decodedId = Decrypt("campus", token);
        const data = {
          campusId: decodedId,
          name: this.name,
          review: this.review,
          calification: this.calification,
        };
        this.$store.dispatch(UPDATE_REVIEW, data).then(({ data }) => {
          this.save_loading = false;
          if (data) {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            /*setTimeout(() => {
              this.$router.push("/created");
            }, 2000);*/
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.content-review {
  width: 80%;
  margin: auto;
}
.padding-top--50 {
  padding-top: 50px;
}
.message-bottom {
  position: absolute;
  bottom: 0px;
  right: 10px;
}

.font-size-1-3 {
  font-size: 1.3rem;
}
</style>
