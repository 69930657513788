<template>
  <div
    class="page-content-review"
    :style="{ backgroundImage: `url(${backgroundImage})` }"
  >
    <v-row justify="center" class="content-card mt-5">
      <v-col lg="10" md="10" sm="10" cols="11">
        <v-card class="h-100">
          <v-card-text class="h-100">
            <v-row class="h-100" justify="center">
              <v-col
                lg="4"
                md="4"
                sm="4"
                cols="12"
                class="content-info-campus"
                v-if="cName"
              >
                <div class="content-logo-campus">
                  <div class="d-block">
                    <v-avatar class="ma-3" size="160" tile>
                      <img :src="picture" />
                    </v-avatar>
                  </div>
                  <div class="mt-1 d-block">
                    <h3 class="font-size-1-2">
                      {{ cName }}
                    </h3>
                  </div>
                </div>
                <v-row class="ml-5">
                  <v-col lg="4" md="4" sm="4" cols="12">
                    <strong>{{ $t("CAMPUS.ADDRESS") }}:</strong>
                  </v-col>
                  <v-col lg="8" md="8" sm="8" cols="12">
                    {{ cAddress }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                lg="4"
                md="4"
                sm="4"
                cols="12"
                class="container-spinner"
                v-else
              >
                <b-spinner
                  variant="primary"
                  label="Spinning"
                  class="spinner--big"
                />
              </v-col>
              <v-col lg="8" md="8" sm="8" cols="12" class="h-100">
                <Form
                  :email="email"
                  :campusReviewId="campusReviewId"
                  :campusName="cName"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Form from "./Edit";
import { Decrypt } from "@/core/services/utils/encryptService";
import { GET_REVIEW } from "@/core/services/store/reviews/reviews.module";
export default {
  components: {
    Form,
  },
  data() {
    return {
      step: 1,
      email: null,
      campusReviewId: null,
      cAddress: null,
      cEmail: null,
      cName: null,
      cPhone: null,
      campus: null,
    };
  },
  created() {
    const { token } = this.$route.params;
    const decodedId = Decrypt("campus", token);
    this.$store.dispatch(GET_REVIEW, decodedId).then(data => {
      this.campus = data;
      this.cAddress = data.address;
      this.cEmail = data.email;
      this.cName = data.name;
      this.cPhone = data.phone;
    });
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-2.jpg";
    },
    picture() {
      const defaultImage = `${process.env.BASE_URL}media/users/blank.png`;
      if (this.campus) {
        return this.campus.photo
          ? `${this.campus.photo.url}?h=100&w=100&fit=crop`
          : defaultImage;
      } else {
        return defaultImage;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-content-review {
  width: 100%;
  height: 100vh;

  .content-logo {
    position: relative;
    width: 90%;
    margin: 10px auto;

    img {
      width: 10%;
    }
  }

  .content-card {
    height: 78%;
  }

  .content-info-campus {
    border-right: 1px solid #e1e1e1 !important;
  }

  .content-logo-campus {
    width: 100%;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .font-size-1-1 {
    font-size: 1.1rem !important;
  }

  .font-size-1-2 {
    font-size: 1.2rem !important;
  }

  .container-spinner {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .spinner--big {
    width: 5rem;
    height: 5rem;
  }
}
</style>
